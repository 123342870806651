



<template>
  <div>
    <v-app>
      <v-main :class="($vuetify.theme.dark) ? 'grey darken-4' : 'primary lighten-3'">
       <v-container class="pb-6 pt-0 px-0" >
          
         
          <v-row class="ma-0 pa-0">
          <v-col cols="2" class="d-none d-sm-flex mx-auto ma-0 pa-0" >
              
               <wallet v-if="this.$store.state.account.address"/>

            </v-col >

             <v-col cols="12" sm="8" class="mx-auto pa-0 mt-n4">
              <v-sheet color="primary lighten-3"  min-height="90vh" rounded="lg" elevation="6">
     
     <buy-item-item :thisitem="thisitem" />
  <v-row class="mx-0 mt-2">
     <v-btn icon plain @click="$router.go(-1)"> <v-icon >
        mdi-arrow-left
      </v-icon></v-btn><v-spacer/><v-btn icon @click="$router.go()" ><v-icon left>
        mdi-refresh
      </v-icon></v-btn></v-row>
      
  <v-btn block plain @click="$router.push('/')"> <v-icon left>
        mdi-arrow-left
      </v-icon>To All Items</v-btn>

              </v-sheet>
            </v-col>
              <v-col cols="12" sm="2" class="d-none d-sm-flex">
              <!--<v-sheet rounded="lg" min-height="268" elevation="2">
                
              </v-sheet>-->
            </v-col>
       </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

const
  axios
 = require('axios');

export default {

  data() {
    return { 

      //thisitem: {}
    };
  },

  created(){
    this.getItem()
  },

  methods:{
    
  async getItem() {
console.log("getting..")
         const url = `${process.env.VUE_APP_API}/${process.env.VUE_APP_PATH.replace(/\./g, '/')}/${"item/"+ this.$route.params.id}`;
      const body = (await axios.get(url)).data.Item

console.log(url)
console.log(body)
       
     this.thisitem = body
    },
  }
  /*created(){
if(axios){
  console.log("axios")
}
console.log("getting..")
         const url = `${process.env.VUE_APP_API}/${process.env.VUE_APP_PATH.replace(/\./g, '/')}/${"item/"+ this.$route.params.id}`;


      axios.get(url).then(data => this.result = data.Item);

console.log(url)
console.log(this.result)
       
     this.thisitem = this.result
    },

*/
  }
  

</script>
